<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="documents"
                            item-key="document_title"
                            class="ph-nonex-datatable"
                        >   

                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    text
                                    color="primary"
                                    @click="edit(item)"
                                    >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

    <v-dialog
      v-model="dialog"
      width="900px"
      persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5" id="dialog_title">Update {{documentMaintenance.document_title}} Trigger</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col :cols="6">
                            <v-menu
                                v-model="documents_maintenance.primary_date.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents_maintenance.primary"
                                        label="Primary Trigger"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    @input="primary"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="6">
                            <v-menu
                                v-model="documents_maintenance.secondary_date.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents_maintenance.secondary"
                                        label="Secondary Trigger"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    @input="secondary"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
                fixed
                color="primary"
                @click="reset"
            >
                reset to default trigger
            </v-btn>
            <v-btn
                outlined color="primary"
                text
                @click="cancel"
            >
                Cancel
            </v-btn>

            <v-btn
                v-if="documents_maintenance.primary && documents_maintenance.secondary"
                color="primary"
                @click="update"
            >
                Update
            </v-btn>
            <v-btn v-else
                disabled
                color="primary"
                @click="update"
            >
                Update
            </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Users',
    data () {
        return {
        headers: [
            {
            text: 'Document Title',
            align: 'start',
            sortable: true,
            value: 'document_title',
            },
            { text: 'Primary Trigger (Month - Day)', value: 'primary_trigger'},
            { text: 'Secondary Trigger (Month - Day)', value: 'secondary_trigger' },
            // { text: 'Status (Default)', value: 'is_default' },
            { text: 'Action', value: 'action' },
        ],
            documents: [],
            dialog: false,
        }
    },
    computed: {
        ...mapGetters({
            documentMaintenance: 'salesProspecting/documentMaintenance',
            documents_maintenance: 'salesProspecting/documents_maintenance'
        })
    },
    async mounted () {
        const res = await this.$store.dispatch('salesProspecting/doGetEmailTriggerSched')
        this.documents = JSON.parse(JSON.stringify(res.data.result))
        console.log(this.documents)
    },
    methods: {
        edit(item){
            console.log(item)
            this.documents_maintenance.document_title = item.document_title
            this.documents_maintenance.is_default = false
            this.dialog = true
            this.$store.commit('salesProspecting/SET_DOCUMENTS_MAINTENANCE', item)
        },
        cancel(){
            this.dialog = false
            this.$store.commit('salesProspecting/SET_DOCUMENTS_MAINTENANCE_DEFAULT')
            this.$store.commit('salesProspecting/SET_DOCS_MAINTENANCE_DEFAULT')
        },
        primary(item){
            let newItem = (item.slice(5));
            this.documents_maintenance.primary = newItem
            this.documents_maintenance.primary_date.menuIssued = false
        },
        secondary(item){
            let newItem = (item.slice(5));
            this.documents_maintenance.secondary = newItem
            this.documents_maintenance.secondary_date.menuIssued = false
        },
        async update(){
            const result = await this.$store.dispatch('salesProspecting/doUpdateEmailTrigger', this.documents_maintenance)
            if (result.status === 200) {
				const res = await this.$store.dispatch('salesProspecting/doGetEmailTriggerSched')
                this.documents = JSON.parse(JSON.stringify(res.data.result))
                this.dialog = false
                this.$store.commit('salesProspecting/SET_DOCUMENTS_MAINTENANCE_DEFAULT')
                this.$store.commit('salesProspecting/SET_DOCS_MAINTENANCE_DEFAULT')
			}
        },
        async reset(){
            this.$store.commit('salesProspecting/SET_DOCS_MAINTENANCE_RESET')
            console.log(this.documents_maintenance)
            const result = await this.$store.dispatch('salesProspecting/doUpdateEmailTrigger', this.documents_maintenance)
            if (result.status === 200) {
				const res = await this.$store.dispatch('salesProspecting/doGetEmailTriggerSched')
                this.documents = JSON.parse(JSON.stringify(res.data.result))
                this.dialog = false
                this.$store.commit('salesProspecting/SET_DOCUMENTS_MAINTENANCE_DEFAULT')
                this.$store.commit('salesProspecting/SET_DOCS_MAINTENANCE_DEFAULT')
			}
        }
    }

}
</script>
<style scoped>
    .v-data-table.ph-nonex-datatable {
    /* background: #FFFFFF; */
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    }

    .ph-nonex-sender-container,
    .ph-nonex-receiver-container {
    display: flex;
    }

    .ph-nonex-table-avatar {
    margin: auto 0;
    }

    .ph-nonex-table-names-text,
    .ph-nonex-status-text {
    margin: auto;
    margin-left: 10px;
    }

    .ph-nonex-status-circle {
    height: 10px;
    width: 10px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: auto 0;
    }

    .ph-nonex-status-success {
    background: #46B746;
    }

    .ph-nonex-status-success-text {
    color: #46B746;
    }

    .ph-nonex-status-pending {
    background: #F76F34;
    }

    .ph-nonex-status-pending-text {
    color: #F76F34;
    }

    .ph-nonex-status-container {
    display: flex;
    }

    .ph-nonex-action-container {
    display: flex;
    flex-direction: row;
    }

    .ph-wu-logo {
    height: 25px;
    width: 10px;
    }

    .ph-nonex-logo {
    transform: scale(0.45);
    border-radius: 0;
    }

    .ph-fulfill-logo {
    transform: scale(0.7);
    border-radius: 0;
    }

    .ph-tooltip {
    background: #EDEDED;
    color: #000;
    border-radius: 50px;
    }

    .ph-nonex-table-names-text.text-amount {
    margin-left: 0 !important;
    }

    .text-inquire-button {
    cursor: pointer;
    }
</style>
